import { useMemo } from 'react'

import { useIntl } from 'react-intl'

type SortProduct<SortBy, SortOrder> = [SortBy, SortOrder]

const prod2Key = <SortBy, SortOrder>(
  p: SortProduct<SortBy, SortOrder>
): string => `${p[0]}&${p[1]}`
// need to throw type safety out the window here because of Dropdown's
// implementation
const key2Prod = <SortBy, SortOrder>(
  s: string
): SortProduct<SortBy, SortOrder> =>
  s.split('&') as unknown as SortProduct<SortBy, SortOrder>

type RemoveOption = {
  sortBy: string
  sortOrder: string
}

type SortOptions = {
  labelPrefix?: string
  removeOptions?: RemoveOption[]
}

type AnySortEnum = Record<string, string>

type SortControls<SortBy, SortOrder> = {
  sortState: { sortBy: SortBy; sortOrder: SortOrder }
  setSort: (sortBy: SortBy, sortOrder: SortOrder) => void
  sortBy: AnySortEnum
  sortOrder: AnySortEnum
  sortOptions: SortOptions
}

const useSortOptions = <SortBy, SortOrder>({
  sortBy,
  sortOrder,
  sortOptions,
}: SortControls<SortBy, SortOrder>) => {
  const intl = useIntl()

  return useMemo(() => {
    const sortByValues = Object.values(sortBy)
    const sortOrderValues = Object.values(sortOrder)

    const options: { label: string; value: string }[] = []

    for (let i = 0; i < sortByValues.length; i++) {
      const sortBy = sortByValues[i]
      for (let k = 0; k < sortOrderValues.length; k++) {
        const sortOrder = sortOrderValues[k]

        if (
          // check if option from removeOptions param matches
          sortOptions.removeOptions?.filter(
            (option) =>
              option.sortBy === sortBy && option.sortOrder === sortOrder
          ).length === 0
        ) {
          options.push({
            label: intl.formatMessage({
              id: `${
                (sortOptions.labelPrefix && `${sortOptions.labelPrefix}.`) || ''
              }${sortBy}.${sortOrder}`,
            }),
            value: prod2Key([sortBy, sortOrder]),
          })
        }
      }
    }

    return options
  }, [intl, sortBy, sortOptions, sortOrder])
}

export type { SortControls, AnySortEnum }
export { key2Prod, prod2Key }
export default useSortOptions
