import { useRef, useState } from 'react'

import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import { styled, css } from 'styled-components'

import { Button, FormattedMessage } from '@b-stock/bstock-react'
import { designColors, typography } from '@b-stock/bstock-react/theme'

import type { Range } from '@commonTypes'
import { Popover } from '@components/Popover'
import { RoundedScroll } from '@components/scroll'
import type { AnyFilterState } from '@components/SearchProvider'

import DateRange from './filtertype/DateRange'
import type { DateRangeType } from './filtertype/DateRange/DateRange'
import MoneyRange from './filtertype/MoneyRange'
import MultiChecklist from './filtertype/MultiChecklist'
import { calculateFilterAppliedText, getShouldShowFilterApplied } from '..'
import type {
  FilterControls,
  DropdownFilterTypes,
  AlphabetizedMultiChecklist,
  ChecklistItem,
  RangeItem,
} from '../types'
import { DropdownFilterType } from '../types'
import { calculateSelectedFilterCount, isRange } from '../utils'

const FilterButton = styled(Button)<{ $selected: boolean }>`
  &&& {
    white-space: nowrap;
    height: 36px;
    font-size: 14px;
    padding: 0 24px;

    ${(o) =>
      o.$selected &&
      css`
        color: ${designColors.neutral.white};
        background: ${designColors.primary.brightBlue};
      `}

    // placeholder for gap property to support safari version <= 14
    & > * + * {
      margin-left: 0.75rem;
    }
  }
`

const DownArrow = styled(FontAwesomeIcon)`
  font-size: 1rem;
`

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 0.875rem;
  height: 0.875rem;
  color: ${designColors.primary.brightBlue};
  background: white;
  border-radius: 50%;

  ${typography.subtitle3}
`

const FilterPopupContainer = styled.div`
  min-width: 320px;
  background: white;
  border: 1px solid ${designColors.neutral.mediumGray};
  box-shadow: 0 2px 12px rgba(218, 221, 223, 0.25);
  border-radius: 0 0 3px 3px;
`

const ExitRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 6px;
`

type FilterContentProps = {
  $maxHeight?: number | string
}
const FilterContent = styled.div<FilterContentProps>`
  ${RoundedScroll};

  overflow-y: auto;
  padding: 0 0 20px 0;

  & > :last-child {
    border-bottom: none;
  }

  ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${typeof $maxHeight === 'number'
        ? `${$maxHeight}px`
        : $maxHeight};
    `}
`

const FilterActions = styled.div`
  background: white;
  box-shadow: 0px -4px 12px rgba(218, 221, 223, 0.25);
  border-top: 1px solid ${designColors.neutral.mediumGray};

  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 24px 16px;

  // placeholder for gap property to support safari version <= 14
  & > * + * {
    margin-left: 12px;
  }
`

type NewFilterState = string[] | DateRangeType | Range
type UpdateFilter<FiltersState> = (
  filterKey: keyof FiltersState,
  newFilterState: NewFilterState
) => void

type GenerateFiltersProps<FiltersState> =
  | {
      hasTempState: true
      filters: DropdownFilterTypes<FiltersState>[]
      tempFilterState: FiltersState
      setTempFilterState: React.Dispatch<React.SetStateAction<FiltersState>>
      setPrioritizedFilter?: (filterKey?: keyof FiltersState) => void
    }
  | {
      hasTempState?: false
      filters: DropdownFilterTypes<FiltersState>[]
      filterControls: FilterControls<FiltersState>
    }

const GenerateFilters = <FiltersState extends AnyFilterState>(
  props: GenerateFiltersProps<FiltersState>
) => {
  const filterState = props.hasTempState
    ? props.tempFilterState
    : props.filterControls.filterState

  const updateFilter: UpdateFilter<FiltersState> = (
    filterKey,
    newFilterState
  ) => {
    let castedNewFilterState
    if (filterKey === 'totalAmountDue' && isRange(newFilterState)) {
      castedNewFilterState = {
        [filterKey]: newFilterState,
        paymentStatus: ['UNPAID'],
      }
    } else {
      castedNewFilterState = {
        [filterKey]: newFilterState,
      }
    }

    if (props.hasTempState) {
      typeof props.setPrioritizedFilter === 'function' &&
        props.setPrioritizedFilter(filterKey)

      props.setTempFilterState({
        ...props.tempFilterState,
        ...castedNewFilterState,
      })
    } else {
      props.filterControls.updateFilters(
        castedNewFilterState as Partial<FiltersState>
      )
    }
  }

  return (
    <>
      {(props.filters || []).map(
        (filter: DropdownFilterTypes<FiltersState>, index) => {
          switch (filter.type) {
            case DropdownFilterType.MultiChecklist:
              return (
                <MultiChecklist<FiltersState>
                  key={`${filter.type}-${index}`}
                  {...filter}
                  filterState={filterState}
                  updateFilter={updateFilter}
                />
              )
            case DropdownFilterType.AlphabetizedMultiChecklist:
              return (
                <div key={`${filter.type}-${index}`}>
                  <h2>{filter.title}</h2>alphabetized checklist
                </div>
              )
            case DropdownFilterType.MoneyRange:
              return (
                <MoneyRange
                  key={`${filter.type}-${index}`}
                  {...filter}
                  filterState={filterState}
                  updateFilter={updateFilter}
                />
              )
            case DropdownFilterType.DateRange:
              return (
                <DateRange
                  key={`${filter.type}-${index}`}
                  {...filter}
                  filterState={filterState}
                  updateFilter={updateFilter}
                />
              )
            default:
              console.warn('Incorrect filter type.')
              return null
          }
        }
      )}
    </>
  )
}

type DropdownFilterProps<FiltersState> = {
  buttonLabel: string
  maxHeight?: number | string
  filters: DropdownFilterTypes<FiltersState>[]
  filterControls: FilterControls<FiltersState>
  setPrioritizedFilter?: (filterKey?: keyof FiltersState) => void
}

const DropdownFilter = <FiltersState extends AnyFilterState>({
  buttonLabel,
  maxHeight,
  filters,
  filterControls,
  setPrioritizedFilter,
}: DropdownFilterProps<FiltersState>) => {
  // create a temp state for the filters because we only want results after clicking "Show Results" button
  const [tempFilterState, setTempFilterState] = useState(
    filterControls.defaultFilterState
  )
  //console.log('[TEMP FILTER STATE]', tempFilterState)
  //console.log('[CURRENT FILTER STATE]', filterControls.filterState)

  const intl = useIntl()

  const filterButtonRef = useRef<HTMLButtonElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const filterKeys = filters.map((filter) => filter.filterKey)

  const selectedFiltersCount = calculateSelectedFilterCount(
    filters,
    filterControls
  )

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setTempFilterState(filterControls.filterState)
    setIsOpen((isOpen) => !isOpen)
  }

  const handleShowResults = () => {
    filterControls.updateFilters(tempFilterState)
    setIsOpen(false)
  }

  const handleClearResults = () => {
    // makes sure only filters in this specific dropdown are reset
    let filtersToReset = filterKeys.reduce<Partial<FiltersState>>(
      (filterObj, filterKey) => {
        filterObj[filterKey] = filterControls.defaultFilterState[filterKey]
        return filterObj
      },
      {}
    )
    // for totalAmountDue also reset paymentStatus filter
    if (filterKeys.includes('totalAmountDue')) {
      filtersToReset = {
        ...filtersToReset,
        paymentStatus: [],
      }
    }

    filterControls.updateFilters(filtersToReset)
    setIsOpen(false)
    typeof setPrioritizedFilter === 'function' && setPrioritizedFilter() // trigger the filter bar to update size calculation
  }

  const showFilterApplied = getShouldShowFilterApplied(
    filters,
    selectedFiltersCount
  )

  return (
    <div>
      <FilterButton
        ref={filterButtonRef}
        type="button"
        appearance="secondary"
        onClick={handleOnClick}
        $selected={!!selectedFiltersCount}
      >
        <span>
          <FormattedMessage
            id={showFilterApplied ? `${buttonLabel}.short` : buttonLabel}
            defaultMessage={
              showFilterApplied ? `${buttonLabel}.short` : buttonLabel
            }
            {...(showFilterApplied && {
              values: {
                value: calculateFilterAppliedText(
                  filters,
                  filterControls,
                  intl
                ),
              },
            })}
          />
        </span>
        {selectedFiltersCount > 1 && <Circle>{selectedFiltersCount}</Circle>}
        <DownArrow icon={isOpen ? faAngleUp : faAngleDown} />
      </FilterButton>
      {typeof window !== 'undefined' && (
        <Popover
          isOpen={isOpen}
          parentElement={filterButtonRef.current}
          setIsOpen={setIsOpen}
          options={{ placement: 'bottom-start' }}
        >
          <FilterPopupContainer>
            <ExitRow>
              <Button onClick={handleOnClick} type="button" size="large">
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </ExitRow>
            <FilterContent $maxHeight={maxHeight}>
              <GenerateFilters<FiltersState>
                hasTempState
                filters={filters}
                tempFilterState={tempFilterState}
                setTempFilterState={setTempFilterState}
                setPrioritizedFilter={setPrioritizedFilter}
              />
            </FilterContent>
            <FilterActions>
              <Button onClick={handleClearResults}>Clear</Button>
              <Button onClick={handleShowResults}>Show Results</Button>
            </FilterActions>
          </FilterPopupContainer>
        </Popover>
      )}
    </div>
  )
}

export { DropdownFilterType, GenerateFilters }

export type {
  ChecklistItem,
  RangeItem,
  MultiChecklist,
  AlphabetizedMultiChecklist,
  MoneyRange,
  DateRange,
  DropdownFilterTypes,
  UpdateFilter,
}
export default DropdownFilter
