import { styled } from 'styled-components'

import { CurrencyRange, FormattedMessage } from '@b-stock/bstock-react'
import { breakpoints } from '@b-stock/bstock-react/theme'

import type { Range } from '@commonTypes'
import type { AnyFilterState } from '@components/SearchProvider'

import type { UpdateFilter } from '../..'
import { Container, Title } from '../MultiChecklist/MultiChecklist'

import 'rc-slider/assets/index.css'

const StyledCurrencyRange = styled(CurrencyRange)`
  && {
    * {
      font-family: 'Proxima Nova';
    }
  }

  width: 280px;

  @media ${breakpoints.max.medium} {
    width: 100%;
  }
`

type MoneyRangeProps<FiltersState> = {
  title?: string
  filterKey: keyof FiltersState
  filterState: FiltersState
  updateFilter: UpdateFilter<FiltersState>
}

const MoneyRange = <FiltersState extends AnyFilterState>({
  title,
  filterKey,
  filterState,
  updateFilter,
}: MoneyRangeProps<FiltersState>) => {
  const range = filterState[filterKey] as Range
  const defaultMin = 0
  const defaultMax = 100000

  const setRange = (value: Range) => {
    if (value[0] === defaultMin && value[1] === defaultMax) {
      updateFilter(filterKey, [null, null])
    } else {
      updateFilter(filterKey, value)
    }
  }

  return (
    <Container>
      {title && (
        <Title>
          <FormattedMessage id={title} defaultMessage={title} />
        </Title>
      )}
      <StyledCurrencyRange
        min={defaultMin}
        max={defaultMax}
        initialMin={range[0] ?? defaultMin}
        initialMax={range[1] ?? defaultMax}
        onChange={setRange}
        labels={{
          min: (
            <FormattedMessage
              id="MoneyRange.min"
              defaultMessage="MoneyRange.min"
            />
          ),
          max: (
            <FormattedMessage
              id="MoneyRange.max"
              defaultMessage="MoneyRange.max"
            />
          ),
        }}
      />
    </Container>
  )
}

export default MoneyRange
