import { keepPreviousData } from '@tanstack/react-query'

import type {
  BidListItem,
  AccountUniqueBids,
} from '@b-stock/auction-api-client'
import {
  getSearchClient,
  getBidsClient,
  canReadAsBuyer,
  buildAxiosRequestConfig,
  fetchAllResultsById,
  makeOptionallyAuthenticatedQuery,
} from '@b-stock/bstock-next'
import type {
  SearchListingsResponse,
  ListingSchema,
  ListingsSearchApiSearchListingsRequest,
  GetFiltersResponse,
} from '@b-stock/search-api-client'

export enum BidStatus {
  WINNING = 'WINNING',
  WON = 'WON',
  LOSING = 'LOSING',
  LOST = 'LOST',
  NO_BID = 'NO_BID',
}

export type SearchResult = ListingSchema & {
  bid?: BidListItem
  bidStatus: BidStatus
}

export type SearchResultsPage = Omit<SearchListingsResponse, 'listings'> & {
  listings: SearchResult[]
  allFilters: GetFiltersResponse
}

export type SearchParams = {
  params: Omit<ListingsSearchApiSearchListingsRequest, 'query'> & {
    query: string | null | undefined
  }
  fetchFilters?: boolean
}

const getBidsQueryParameters = (ids: string[], limit: number) => ({
  auctionId: ids.join(','),
  limit,
})

const bidsResponseToResults = (response: AccountUniqueBids) => response.bids

const getBidStatus = (bid?: BidListItem): BidStatus => {
  if (!bid) return BidStatus.NO_BID

  if (bid.auction.closed) {
    return bid.winning ? BidStatus.WON : BidStatus.LOST
  } else {
    return bid.winning ? BidStatus.WINNING : BidStatus.LOSING
  }
}

// Drives ALL AUCTIONS and Seller Auctions pages
const getSearchData = async (
  accessToken: string | null,
  { params, fetchFilters = true }: SearchParams
): Promise<SearchResultsPage> => {
  const searchClient = getSearchClient()
  const bidsClient = getBidsClient()
  const axiosParams = buildAxiosRequestConfig({ accessToken })
  const sellerId = params.sellerId

  const [{ data: searchResponse }, { data: filtersResponse }] =
    await Promise.all([
      searchClient.searchListings(
        {
          ...params,
          query: params.query || undefined,
        },
        axiosParams
      ),
      // request filters when necessary
      ...(fetchFilters
        ? [
            searchClient.getFilters(
              {
                ...(sellerId && { sellerId }),
              },
              axiosParams
            ),
          ]
        : [Promise.resolve({ data: {} })]),
    ])

  const auctionIds = searchResponse.listings
    .map((result) => result.auctionId)
    .filter((x) => x)

  const bidsByAuctionId: Partial<Record<string, BidListItem>> = {}

  // Only query for bids if the user is logged in as a buyer
  if (canReadAsBuyer(accessToken)) {
    const bids = await fetchAllResultsById({
      apiClient: bidsClient,
      query: bidsClient.getAccountUniqueBids,
      ids: auctionIds,
      getQueryParameters: getBidsQueryParameters,
      responseToResults: bidsResponseToResults,
      axiosOptions: buildAxiosRequestConfig({ accessToken }),
      limit: 100,
    })

    bids.forEach((bid) => {
      if (bid.auctionId) {
        bidsByAuctionId[bid.auctionId] = bid
      }
    })
  }

  const augmentedResult: SearchResult[] = searchResponse.listings.map(
    (result: ListingSchema) => {
      const bid = bidsByAuctionId[result.auctionId || '']
      return {
        ...result,
        bidStatus: getBidStatus(bid),
        ...(bid ? { bid } : {}),
      }
    }
  )

  return {
    listings: augmentedResult,
    total: searchResponse.total,
    limit: searchResponse.limit,
    offset: searchResponse.offset,
    availableFilters: searchResponse.availableFilters,
    allFilters: filtersResponse,
  }
}

const listingSearchQuery = makeOptionallyAuthenticatedQuery(
  'search',
  getSearchData,
  {
    placeholderData: keepPreviousData,
  }
)

export default listingSearchQuery
