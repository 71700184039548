import type * as React from 'react'

import { ErrorBoundary } from '@b-stock/bstock-react'

import { ContentContainer } from '@components/ContentContainer'
import { useLogError } from '@helpers/useLogError'

import { Footer } from './Footer'
import { Header } from './Header'

type StandardLayoutProps = {
  /**
   * Flag to disable automatic wapping of children in a container that provides
   * standard horizontal spacing. Use this if you need edge-to-edge content
   * on your page. You can combine this with `StandardLayout.ContentContainer`
   * to have some elements which are edge-to-edge but others which conform
   * to the normal centered design.
   */
  noContainer?: boolean
  /**
   * Flag to disable the search bard in the header component.
   */
  noSearchBar?: boolean
  /**
   * Flag to disable the header.
   */
  noHeader?: boolean
  children: React.ReactNode
}

/**
 * Wraps page content with the standard header/footer and general layout
 * elements.
 */
const StandardLayout = ({
  children,
  noContainer,
  noSearchBar,
  noHeader,
}: StandardLayoutProps) => {
  const logError = useLogError()
  return (
    <>
      {noHeader ? null : <Header noSearchBar={noSearchBar} />}

      <ErrorBoundary onError={logError}>
        {noContainer ? (
          children
        ) : (
          <ContentContainer>{children}</ContentContainer>
        )}
      </ErrorBoundary>

      <Footer />
    </>
  )
}

StandardLayout.ContentContainer = ContentContainer

export default StandardLayout
